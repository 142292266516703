import React from 'react';
import clsx from 'clsx';
import Img from 'gatsby-image/withIEPolyfill';
import styles from './styles.module.sass';

export default ({
  image,
  alt,
  selected
}) => (
  <div className={clsx(
      styles.showcaseImage,
      selected ? '' : styles.showcaseImageHidden
    )}>
    <Img imgStyle={{ objectFit: 'contain' }}
      objectFit='contain'
      objectPosition='center bottom'
      className={styles.imageContainer}
      fluid={image.fluid}
      alt={alt}/>
  </div>
);