import React from 'react';
import { Grid } from '@material-ui/core';
import { Link } from 'gatsby';
import Typography from '../../../components/Typography';
import styles from './styles.module.sass';

export default ({ industries }) => (
  <Grid container justify='flex-start'
    alignItems='flex-start'
    className={styles.container}>
    {industries.map(({ text, url }) => (
      <Grid item className={styles.industryItem}
        key={text}
        alignItems='center'>
        <Link to={url}>
          <div className={styles.industryContainer}>
            <Typography className={styles.industryText}
              variant='h5'
              weight='light'
              color='dark'>
              {text}
            </Typography>
            <div className={styles.arrow}>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 34.1 24"
                height='100%'
                width="100%">
                <defs><style>{'.cls-2{fill:none;stroke:#000;stroke-miterlimit:10;stroke-width:3px}'}</style></defs>
                <path className='cls-2' d="M21.1 1.1L32 12 21.1 23M0 12h32"/>
              </svg>
            </div>
          </div>
        </Link>
      </Grid>
    ))}
  </Grid>
)