import React from 'react';
import i18n from '../../../locale';

export default [{
  title: i18n('Fleet overview'),
  name: 'fleet-management',
  description: i18n('Easy and simple fleet monitoring that gives an overview of the fleet.'),
  tile: ({ className, fill, ...props }) => (
    <svg xmlns="http://www.w3.org/2000/svg"
      className={className}
      {...props}
      viewBox="0 0 72.8 72.8"
      width='100%'
      height='100%'>
      <path className={fill}
        d="M 25.4 34.3 a 2.2 2.2 0 0 0 -2.2 2.2 c 0 1.6 1.4 3.1 2.2 3.9 c 0.8 -0.8 2.2 -2.4 2.2 -3.9 a 2.2 2.2 0 0 0 -2.2 -2.2 Z M 25.4 19.9 a 2.2 2.2 0 0 0 -2.2 2.2 c 0 1.6 1.4 3.2 2.2 3.9 s 2.2 -2.4 2.2 -3.9 a 2.2 2.2 0 0 0 -2.2 -2.2 Z M 16.9 33.2 c 0.8 -0.8 2.2 -2.4 2.2 -4 a 2.2 2.2 0 0 0 -4.4 0 c 0 1.6 1.4 3.2 2.2 4 Z" />
      <path className={fill}
        d="M 66.2 9 H 6.6 a 5.3 5.3 0 0 0 -5.3 5.3 v 36.2 a 5.2 5.2 0 0 0 5.3 5.2 h 20.3 V 61 h -8.4 a 1.4 1.4 0 0 0 -1.4 1.4 a 1.3 1.3 0 0 0 1.4 1.3 h 35.8 a 1.3 1.3 0 0 0 1.4 -1.3 a 1.4 1.4 0 0 0 -1.4 -1.4 h -8.4 v -5.3 h 20.3 a 5.2 5.2 0 0 0 5.2 -5.2 V 14.3 A 5.2 5.2 0 0 0 66.2 9 Z M 43.1 61 H 29.7 v -6.5 h 13.4 Z M 6.6 53 a 2.5 2.5 0 0 1 -2.5 -2.5 v -3.1 h 64.6 v 3.1 a 2.5 2.5 0 0 1 -2.5 2.5 Z m 51.7 -35.8 h 10.4 v 5 H 58.3 Z m 0 6.5 h 10.4 v 4.2 H 58.3 Z m 0 5.7 h 10.4 V 34 H 58.3 Z m 0 6.1 h 10.4 v 4.1 H 58.3 Z m 0 5.6 h 10.4 v 4.8 H 58.3 Z m 10.4 -25.4 H 58.3 v -3.9 h 7.9 a 2.5 2.5 0 0 1 2.5 2.5 Z M 56.8 45.9 H 4.1 V 14.3 a 2.5 2.5 0 0 1 2.5 -2.5 h 50.2 Z" />
      <path className={fill}
        d="M 36.4 19.9 a 2.2 2.2 0 0 1 2.2 2.2 c 0 1.6 -1.4 3.2 -2.2 3.9 s -2.2 -2.4 -2.2 -3.9 a 2.2 2.2 0 0 1 2.2 -2.2 Z M 45.2 33.2 c -0.7 -0.8 -2.1 -2.4 -2.1 -4 a 2.1 2.1 0 0 1 2.1 -2.1 a 2.1 2.1 0 0 1 2.2 2.1 c 0 1.6 -1.4 3.2 -2.2 4 Z" />
    </svg>
  )
}, {
  title: i18n('Automatic trip logging'),
  name: 'automatic-logbook',
  description: i18n('All journeys are automatically tracked. Relevant information about departure/arrival, addresses, duration, length, and type of trip are available, also in a historical view.'),
  tile: ({ className, fill, ...props }) => (
    <svg xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 72.8 72.8"
      className={className}
      {...props}
      width='100%'
      height='100%'>
      <path className={fill}
        d="M 20.1 42.4 h 13.6 a 2.7 2.7 0 0 0 2.4 1.6 a 2.7 2.7 0 0 0 2.5 -1.6 h 13.6 a 8.6 8.6 0 0 0 8.4 -6.5 a 2.8 2.8 0 0 0 2 -2.6 a 2.7 2.7 0 0 0 -2.1 -2.6 a 8.6 8.6 0 0 0 -8.3 -6.2 h -6.7 a 2.7 2.7 0 0 0 -5 0 H 24.3 a 5.9 5.9 0 0 0 -1.4 -3.1 a 9.5 9.5 0 0 0 1.7 -5.3 a 6.6 6.6 0 0 0 -13.2 0 a 10.3 10.3 0 0 0 1.7 5.3 a 6.4 6.4 0 1 0 11.2 5.1 h 16.2 a 2.7 2.7 0 0 0 5 0 h 6.7 a 6.7 6.7 0 0 1 6.3 4.5 a 2.6 2.6 0 0 0 -1.3 2.3 a 2.9 2.9 0 0 0 1.4 2.4 a 6.7 6.7 0 0 1 -6.4 4.7 H 38.7 a 2.8 2.8 0 0 0 -2.6 -1.8 a 2.7 2.7 0 0 0 -2.5 1.8 H 20.1 a 8.7 8.7 0 0 0 -8.4 6.7 a 2.1 2.1 0 0 0 -1.5 2 a 2.2 2.2 0 0 0 1.5 2.1 a 8.6 8.6 0 0 0 8.4 6.6 h 6.6 a 2.8 2.8 0 0 0 2.6 2 a 2.7 2.7 0 0 0 2.6 -2 H 48 a 6.4 6.4 0 0 0 6.3 5.4 a 6.4 6.4 0 1 0 0 -12.7 a 6.4 6.4 0 0 0 -6.3 5.3 H 31.7 a 2.8 2.8 0 0 0 -4.8 0 h -6.8 a 6.5 6.5 0 0 1 -6.4 -4.9 a 2.3 2.3 0 0 0 0.9 -1.8 a 2.4 2.4 0 0 0 -0.9 -1.8 a 6.7 6.7 0 0 1 6.4 -4.9 Z M 18 23 c -1.3 -1.3 -3.8 -4.1 -3.8 -6.9 a 3.9 3.9 0 0 1 7.7 0 c 0 2.8 -2.5 5.6 -3.9 6.9 Z m -2.3 -0.3 l 1.8 1.9 l 0.5 0.5 l 0.5 -0.5 a 16.8 16.8 0 0 0 1.9 -1.9 a 3.7 3.7 0 0 1 1.3 2.8 a 3.7 3.7 0 0 1 -3.7 3.6 a 3.6 3.6 0 0 1 -3.6 -3.6 a 3.7 3.7 0 0 1 1.3 -2.8 Z m 35 34.1 a 3.6 3.6 0 0 1 3.6 -3.6 a 3.7 3.7 0 1 1 0 7.3 a 3.6 3.6 0 0 1 -3.6 -3.7 Z" />
    </svg>
  )
}, {
  title: i18n('Fleet statistics'),
  name: 'fleet-statistics',
  description: i18n('The latest statistics about the fleet are always available. Get valuable insights such as utilization, fuel consumption, and more.'),
  tile: ({ className, fill, ...props }) => (
    <svg xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 72.8 72.8"
      className={className}
      {...props}
      width='100%'
      height='100%'>
      <path className={fill}
        d="M 60.6 33 a 1.6 1.6 0 0 0 -0.9 -0.3 a 1.8 1.8 0 0 0 -1.2 0.5 L 42 45.7 H 25.6 a 1.9 1.9 0 0 0 -1.2 0.4 L 7.4 59 a 2.1 2.1 0 0 0 -0.8 1.6 v 0.7 a 1.9 1.9 0 0 0 2 2 h 51.1 a 1.9 1.9 0 0 0 2 -2 V 34.7 a 1.8 1.8 0 0 0 -1.1 -1.7 Z M 43.2 47.9 l 16 -12.1 v 25 h -16 Z M 25.1 60.8 h -16 l 16 -12.1 Z m 1 -12.6 h 16.1 v 12.6 H 26.1 Z M 59.7 9.4 a 6.6 6.6 0 0 0 -6.5 6.5 c 0 2.9 2.2 6.5 4.3 9.9 l 0.5 0.8 a 2.1 2.1 0 0 0 3.4 0 l 0.5 -0.8 c 2.1 -3.4 4.3 -7 4.3 -9.9 a 6.6 6.6 0 0 0 -6.5 -6.5 Z m 0 16.1 c -2 -3.2 -4.5 -7.1 -4.5 -9.6 a 4.5 4.5 0 0 1 9 0 c 0 2.5 -2.5 6.4 -4.5 9.6 Z" />
      <path className={fill}
        d="M 59.7 13.9 a 2 2 0 0 0 -2 2 a 2 2 0 1 0 4 0 a 2 2 0 0 0 -2 -2 Z M 20.6 39.5 a 14.1 14.1 0 1 0 -14 -14 a 14.1 14.1 0 0 0 14 14 Z m 12.1 -14 a 12 12 0 0 1 -1.9 6.4 l -9.5 -6.6 l 6 -9.8 a 11.8 11.8 0 0 1 5.4 10 Z m -11.6 -12 a 10.9 10.9 0 0 1 5.4 1.5 l -5.4 8.7 Z m -1 0 v 12 a 0.4 0.4 0 0 1 0.1 0.3 h 0.2 l 9.9 6.8 a 12.1 12.1 0 0 1 -21.7 -7.1 a 11.9 11.9 0 0 1 11.5 -12 Z" />
    </svg>
  )
}, {
  title: i18n('Easy communication'),
  name: 'fleet-chat',
  description: i18n('Through the in-built chat the user can be in contact with the service provider (e.g. mechanic) and stay always updated about important events that occur.'),
  tile: ({ className, fill, ...props }) => (
    <svg xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 72.8 72.8"
      className={className}
      {...props}
      width='100%'
      height='100%'>
      <path className={fill}
        d="M 36.3 21.5 h 16.5 a 0.5 0.5 0 0 0 0.5 -0.5 a 0.6 0.6 0 0 0 -0.5 -0.5 H 36.3 a 0.6 0.6 0 0 0 -0.5 0.5 a 0.5 0.5 0 0 0 0.5 0.5 Z M 60.1 26.2 H 36.3 a 0.6 0.6 0 0 0 -0.5 0.5 a 0.5 0.5 0 0 0 0.5 0.5 h 23.8 a 0.5 0.5 0 0 0 0.5 -0.5 a 0.5 0.5 0 0 0 -0.5 -0.5 Z M 10.8 37.3 h 15.8 a 0.5 0.5 0 0 0 0.5 -0.5 a 0.5 0.5 0 0 0 -0.5 -0.5 H 10.8 a 0.5 0.5 0 0 0 -0.5 0.5 a 0.5 0.5 0 0 0 0.5 0.5 Z M 34.4 42 H 10.8 a 0.5 0.5 0 0 0 -0.5 0.5 a 0.5 0.5 0 0 0 0.5 0.5 h 23.6 a 0.5 0.5 0 0 0 0.5 -0.5 a 0.5 0.5 0 0 0 -0.5 -0.5 Z M 34.4 46.8 H 10.8 a 0.5 0.5 0 1 0 0 1 h 23.6 a 0.5 0.5 0 0 0 0 -1 Z" />
      <path className={fill}
        d="M 64.9 12.1 H 32.1 a 7.5 7.5 0 0 0 -7.5 7.4 v 8.4 H 7.9 a 7.5 7.5 0 0 0 -7.5 7.4 v 12.5 a 7.6 7.6 0 0 0 6.9 7.5 v 2.6 a 2.8 2.8 0 0 0 2.9 2.8 a 2.7 2.7 0 0 0 1.9 -0.8 l 4.7 -4.6 h 23.9 a 7.6 7.6 0 0 0 7.5 -7.5 v -8.3 h 5.9 l 4.6 5.3 a 3.3 3.3 0 0 0 2.3 1 a 3 3 0 0 0 3 -3 v -3.3 h 0.9 a 7.6 7.6 0 0 0 7.5 -7.5 V 19.5 a 7.5 7.5 0 0 0 -7.5 -7.4 Z M 61.5 37 v 6.2 a 0.3 0.3 0 0 1 -0.3 0.3 a 0.4 0.4 0 0 1 -0.4 -0.1 L 55.3 37 h -8.6 v -1.3 a 5.9 5.9 0 0 0 -1.3 -3.7 h 14.7 a 0.5 0.5 0 0 0 0 -1 H 44.5 a 6.3 6.3 0 0 0 -4.2 -1.6 H 27.1 v -9.5 a 5.4 5.4 0 0 1 5.4 -5.3 h 32 a 5.4 5.4 0 0 1 5.4 5.3 v 11.7 a 5.4 5.4 0 0 1 -5.4 5.4 Z m -15.8 1 v 9.4 a 5.4 5.4 0 0 1 -5.4 5.4 H 15.7 L 10.5 58 a 0.4 0.4 0 0 1 -0.4 0.1 c -0.1 0 -0.3 -0.1 -0.3 -0.4 v -4.9 H 8.3 a 5.4 5.4 0 0 1 -5.4 -5.4 V 35.7 a 5.4 5.4 0 0 1 5.4 -5.3 h 32 a 5.4 5.4 0 0 1 5.4 5.3 Z" />
    </svg>
  )
}, {
  title: i18n('Personalized reports'),
  name: 'fleet-reports',
  description: i18n('Options to enable relevant reports such as notification about overspeeding, driving logs, geofencing, or usage of the vehicles after working hours.'),
  tile: ({ className, fill, ...props }) => (
    <svg xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 72.8 72.8"
      className={className}
      {...props}
      width='100%'
      height='100%'>
      <path className={fill}
        d="M 52.6 46.4 a 8.2 8.2 0 0 1 -1.4 -4.5 v -9.3 a 15 15 0 0 0 -10.1 -14 v -3.1 a 4.7 4.7 0 1 0 -9.4 0 v 3.1 a 15 15 0 0 0 -10.1 14 v 9.3 a 8.2 8.2 0 0 1 -1.4 4.5 l -1.8 2.4 a 3.3 3.3 0 0 0 -0.3 3.3 a 3.3 3.3 0 0 0 2.9 1.8 h 8.5 a 4.1 4.1 0 0 0 -0.1 1.1 a 7 7 0 1 0 14 0 a 4.1 4.1 0 0 0 -0.1 -1.1 h 8.5 a 3.3 3.3 0 0 0 2.9 -1.8 a 3.3 3.3 0 0 0 -0.3 -3.3 Z M 40.7 55 a 4.3 4.3 0 0 1 -8.6 0 a 4.6 4.6 0 0 1 0.8 -2.4 h 7 a 4.6 4.6 0 0 1 0.8 2.4 Z m 0 -3.9 H 21 c -0.3 0 -0.4 -0.1 -0.4 -0.2 s -0.1 -0.3 0 -0.5 l 1.8 -2.4 a 10.4 10.4 0 0 0 2 -6.1 v -9.3 a 12 12 0 0 1 9.4 -11.7 a 11.4 11.4 0 0 1 5.2 0 a 12 12 0 0 1 9.4 11.7 v 9.3 a 10.4 10.4 0 0 0 2 6.1 l 1.8 2.4 c 0.1 0.2 0 0.4 0 0.5 s -0.2 0.2 -0.4 0.2 Z m -6.3 -31.8 v -3.8 a 2 2 0 0 1 4 0 v 3.8 h -4 Z M 12.4 35.9 a 1.3 1.3 0 0 0 -1.3 -1.4 H 2.5 a 1.4 1.4 0 0 0 0 2.8 h 8.6 a 1.3 1.3 0 0 0 1.3 -1.4 Z M 14.8 23.4 l -8.1 -4.1 a 1.4 1.4 0 0 0 -1.8 0.6 a 1.5 1.5 0 0 0 0.6 1.9 l 8 4 l 0.6 0.2 a 1.6 1.6 0 0 0 1.3 -0.8 a 1.4 1.4 0 0 0 -0.6 -1.8 Z M 59.2 25.9 l 8.1 -4.1 a 1.5 1.5 0 0 0 0.6 -1.9 a 1.4 1.4 0 0 0 -1.8 -0.6 L 58 23.4 a 1.3 1.3 0 0 0 -0.6 1.8 a 1.3 1.3 0 0 0 1.2 0.8 Z M 70.3 34.5 h -8.6 a 1.4 1.4 0 0 0 -1.4 1.4 a 1.3 1.3 0 0 0 1.4 1.3 h 8.6 a 1.4 1.4 0 0 0 1.4 -1.4 a 1.3 1.3 0 0 0 -1.4 -1.3 Z M 12.8 46.3 L 5.5 50 a 1.3 1.3 0 0 0 -0.6 1.8 a 1.3 1.3 0 0 0 1.2 0.8 l 0.6 -0.2 l 7.3 -3.7 a 1.3 1.3 0 0 0 -1.2 -2.4 Z M 67.3 50 L 60 46.3 a 1.4 1.4 0 0 0 -1.9 0.6 a 1.3 1.3 0 0 0 0.6 1.8 l 7.4 3.7 l 0.6 0.2 a 1.3 1.3 0 0 0 1.2 -0.8 a 1.3 1.3 0 0 0 -0.6 -1.8 Z" />
    </svg>
  )
}, {
  title: i18n('Geofencing'),
  name: 'geofence',
  description: i18n('Create different geofences to be notified when vehicles enter or exit a certain area to keep track of the daily workflow or avoid unauthorized usage.'),
  tile: ({ className, fill, ...props }) => (
    <svg xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 72.8 72.8"
      className={className}
      {...props}
      width='100%'
      height='100%'>
      <path className={fill}
        d="M 36.4 15.4 a 4.4 4.4 0 0 0 -4.4 4.4 a 4.4 4.4 0 0 0 8.8 0 a 4.4 4.4 0 0 0 -4.4 -4.4 Z m 0 7.7 a 3.4 3.4 0 1 1 3.4 -3.3 a 3.4 3.4 0 0 1 -3.4 3.3 Z" />
      <path className={fill}
        d="M 46.7 30 l 0.7 -1.6 l 0.6 -1.3 a 20.5 20.5 0 0 0 1.5 -6.9 a 13.1 13.1 0 0 0 -26.2 0 a 20.5 20.5 0 0 0 1.5 6.9 l 0.6 1.4 a 10.1 10.1 0 0 0 0.7 1.5 c -8.2 2.5 -13.5 7.9 -13.5 13.9 c 0 8.7 10.5 15.4 23.8 15.4 s 23.8 -6.7 23.8 -15.4 c 0 -6 -5.3 -11.4 -13.5 -13.9 Z M 38 40.3 a 1.9 1.9 0 0 1 -3.2 0 c -4.6 -7.4 -9 -15 -9 -20.1 a 10.6 10.6 0 0 1 21.2 0 c 0 5.1 -4.4 12.7 -9 20.1 Z m -9.3 -8.4 c 1.5 2.8 3.3 5.8 5.2 8.9 a 2.9 2.9 0 0 0 2.5 1.4 a 3 3 0 0 0 2.5 -1.4 c 1.9 -3.1 3.7 -6.1 5.2 -8.9 c 8.1 1.9 13.6 6.7 13.6 12 c 0 7.1 -9.6 12.9 -21.3 12.9 S 15.1 51 15.1 43.9 c 0 -5.3 5.5 -10.1 13.6 -12 Z M 13.3 33.4 l -1 -1.2 l -0.6 0.6 l -1.5 1.5 l -0.5 0.6 l 1.1 1 l 0.6 -0.6 l 1.4 -1.4 Z M 10.2 52.4 l -0.5 -0.6 l -1.3 0.9 l 0.5 0.6 a 11.5 11.5 0 0 0 1.4 1.7 l 0.5 0.6 l 1.1 -1.1 l -0.5 -0.5 Z M 15.2 57.2 l -0.7 -0.4 l -0.9 1.3 l 0.7 0.4 l 1.8 1.2 l 0.7 0.4 l 0.8 -1.4 l -0.7 -0.4 Z M 7.6 46.1 v -0.8 l -1.6 0.2 v 0.8 a 17 17 0 0 0 0.4 2.2 l 0.2 0.7 l 1.5 -0.4 L 8 48 a 10.6 10.6 0 0 1 -0.4 -1.9 Z M 28.3 62.4 h -0.8 l -0.3 1.5 h 0.8 l 2.1 0.4 h 0.8 l 0.2 -1.6 h -0.8 Z M 8 41.3 a 13.4 13.4 0 0 1 0.7 -1.8 l 0.3 -0.8 l -1.5 -0.6 l -0.3 0.7 a 17.2 17.2 0 0 0 -0.7 2.1 l -0.2 0.7 l 1.5 0.5 Z" />
      <path className={fill}
        d="M 16.8 31 l 1.8 -1 l 0.7 -0.4 l -0.7 -1.4 l -0.7 0.4 l -1.9 1 l -0.7 0.4 l 0.8 1.4 Z M 21.4 60.5 l -0.7 -0.3 l -0.6 1.5 l 0.8 0.3 l 2 0.7 l 0.7 0.2 l 0.5 -1.5 l -0.7 -0.2 Z M 37.4 63 h -2.8 v 1.6 h 3.7 V 63 Z M 65.3 45.3 v 0.8 a 10.6 10.6 0 0 1 -0.4 1.9 l -0.2 0.8 l 1.5 0.4 l 0.2 -0.7 a 17 17 0 0 0 0.4 -2.2 v -0.8 Z M 56 31 l 0.7 0.4 l 0.8 -1.4 l -0.7 -0.4 l -1.9 -1 l -0.7 -0.4 l -0.7 1.4 l 0.7 0.4 Z M 62.6 52.4 L 61.4 54 l -0.6 0.5 l 1.2 1.1 l 0.5 -0.6 a 11.5 11.5 0 0 0 1.4 -1.7 l 0.4 -0.6 l -1.2 -0.9 Z M 64.8 41.3 l 0.2 0.8 l 1.5 -0.5 l -0.2 -0.7 a 17.2 17.2 0 0 0 -0.7 -2.1 l -0.3 -0.7 l -1.5 0.6 l 0.3 0.8 a 13.4 13.4 0 0 1 0.7 1.8 Z M 62 35.9 l 1.1 -1 l -0.5 -0.6 l -1.5 -1.5 l -0.6 -0.6 l -1 1.2 l 0.5 0.5 l 1.4 1.4 Z M 44.5 62.4 l -2 0.3 h -0.8 l 0.2 1.6 h 0.8 l 2.1 -0.4 h 0.8 l -0.3 -1.5 Z M 51.4 60.5 l -2 0.7 l -0.7 0.2 l 0.5 1.5 l 0.7 -0.2 l 2 -0.7 l 0.7 -0.3 l -0.5 -1.5 Z M 57.6 57.2 l -1.7 1.1 l -0.7 0.4 l 0.8 1.4 l 0.7 -0.4 l 1.8 -1.2 l 0.6 -0.4 l -0.9 -1.3 Z" />
    </svg>
  )
}];