import React from 'react';
import { graphql, StaticQuery } from 'gatsby';
import { Grid } from '@material-ui/core';
import i18n from '../../../locale';
import SectionWrapper from '../../../components/SectionWrapper';
import Banner from '../Banner';
import Tile from '../Tile';
import Narrow from '../NarrowEntry';
import Showcase from './Showcase';
import template from './template';
import styles from './styles.module.sass';
import RelevantIndustries from '../RelevantIndustries';

export default () => {
  const [ selected, setShowcase ] = React.useState(0);

  return (
    <StaticQuery
      query={graphql`
        query {
          logo: imageSharp(fluid: {originalName: {eq: "fleet-logo-new-2x.png"}}) {
            fluid(maxWidth: 88) {
              ...GatsbyImageSharpFluid
            }
          }
          shot0: imageSharp(fluid: {originalName: {eq: "Fleet-1.jpg"}}) {
            fluid(maxWidth: 1200, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
          shot1: imageSharp(fluid: {originalName: {eq: "Fleet-2.jpg"}}) {
            fluid(maxWidth: 1200, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
          shot2: imageSharp(fluid: {originalName: {eq: "Fleet-3.jpg"}}) {
            fluid(maxWidth: 1200, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
          shot3: imageSharp(fluid: {originalName: {eq: "Fleet-4.jpg"}}) {
            fluid(maxWidth: 1200, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
          shot4: imageSharp(fluid: {originalName: {eq: "Fleet-5.jpg"}}) {
            fluid(maxWidth: 1200, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
          shot5: imageSharp(fluid: {originalName: {eq: "Fleet-6.jpg"}}) {
            fluid(maxWidth: 1200, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      `}
      render={({ logo, ...data }) => (
        <>
          <Banner url={i18n('_hash:fleet')} logo={logo}
            title='Fleet'
            text={i18n('A fleet management service suitable for a variety of customer segments')}/>
          <SectionWrapper className={styles.container}>
            <Grid container className={styles.contentContainer} justify='space-between' alignContent='space-between'>
              <Grid item sm={12} md={12} lg={8} container
                className={styles.showcaseContainer}>
                {template.map((t, i) => (
                  <Showcase key={i}
                    {...t}
                    selected={selected === i}
                    image={data[`shot${i}`].fluid}/>
                ))}
              </Grid>
              <Grid item sm={12} md={12} lg={8}
                className={styles.showcaseContainerNarrow}>
                {template.map((t, key) => (
                  <Narrow key={key}
                    {...t}
                    selected={selected === key}
                    onChange={() => setShowcase(key)}/>
                ))}
              </Grid>
              <Grid container item sm={12} md={12} lg={4}
                direction='row'
                justify='space-between'
                className={styles.tilesContainer}>
                {template.map((t, i) => (
                  <Tile key={i}
                    Tile={t.tile}
                    selected={i === selected}
                    onClick={() => setShowcase(i)}
                    text={t.title}/>
                ))}
              </Grid>
            </Grid>
          </SectionWrapper>
          <RelevantIndustries industries={[{
              text: i18n('Fleet management'),
              url: i18n('_url:fleet-management')
            }, {
              text: i18n('Leasing'),
              url: i18n('_url:leasing')
            }, {
              text: i18n('Dealerships'),
              url: i18n('_url:dealership')
            }]}/>
        </>
      )}/>
  )
}