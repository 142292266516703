import React from "react";
import Content from '../views/Services';

export default ({location}) => <Content location={location} seo={{
  title: "Connected car tjenester i Danmark",
  lang: 'da-DK',
  description: "Connected car tjenester designet til at løse køretøjsrelaterede opgaver nemt og hurtigt. Lær mere om de digitale tjenester, som OBI+ tilbyder.",
  meta: [{
    name: 'keywords',
    content: 'Connected car tjenester',
  }]
}}/>