import React from 'react';
import i18n from '../../../locale';

export default [{
  title: i18n('Vehicle overview'),
  name: 'driver-app-vehicle-overview',
  description: i18n('An easy overview for each driver. Car location and walking directions. Vehicle condition and information in case of alerts. Fuel level and mileage information.'),
  tile: ({ className, fill, ...props }) => (
    <svg xmlns="http://www.w3.org/2000/svg"
      className={className}
      {...props}
      viewBox="0 0 72.8 72.8"
      width="100%"
      height="100%">
      <defs>
        <clipPath id="clip-path">
          <path fill='none' d="M13.8 34.2l4-4.2-6.1-3.9-4.3 2.8v4.8l6.4.5z"/>
        </clipPath>
        <clipPath id="clip-path-2">
          <path className={fill} d="M55.4 30.2l1.4 2.5 3.3 1.9 5.2-.4.1-7-9.6-4.1-.4 7.1z"/>
        </clipPath>
      </defs>
      <path className={fill} d="M56.3 58.9h-5.5a2.2 2.2 0 0 1-2.2-2.2v-1.8h-24v1.8a2.2 2.2 0 0 1-2.2 2.2h-5.6a2.2 2.2 0 0 1-2.2-2.2v-2.5a6.6 6.6 0 0 1-3.6-5.8v-8.7a5.9 5.9 0 0 1 2.3-4.6l2.4-1.7a3.4 3.4 0 0 0 .8-.9l2.3-3.5c3.3-5.1 5.3-6.3 15.9-6.3h3.7c10.7 0 12.7 1.2 16 6.3.7 1.2 1.5 2.4 2.3 3.5a3.4 3.4 0 0 0 .8.9l2.3 1.7a5.6 5.6 0 0 1 2.3 4.6v8.7a6.6 6.6 0 0 1-3.6 5.8v2.5a2.2 2.2 0 0 1-2.2 2.2zm-31.9-5.5h24.3a1.4 1.4 0 0 1 1.4 1.4v1.9a.7.7 0 0 0 .7.7h5.5a.7.7 0 0 0 .7-.7v-2.6a1.3 1.3 0 0 1 .8-1.2 5 5 0 0 0 2.8-4.5v-8.7a4.2 4.2 0 0 0-1.7-3.4l-2.3-1.7a6.8 6.8 0 0 1-1.2-1.2c-.7-1.2-1.5-2.4-2.3-3.6-2.9-4.4-4.2-5.6-14.7-5.6h-3.7c-10.5 0-11.8 1.2-14.7 5.6-.7 1.2-1.5 2.4-2.3 3.6l-1.1 1.2-2.4 1.7a4.5 4.5 0 0 0-1.7 3.4v8.7a4.9 4.9 0 0 0 2.9 4.5 1.4 1.4 0 0 1 .7 1.2v2.6a.7.7 0 0 0 .7.7h5.6a.7.7 0 0 0 .7-.7v-1.9a1.3 1.3 0 0 1 1.3-1.4z"/>
      <path className={fill} d="M56.1 33.7a.8.8 0 0 1-.7-.4.7.7 0 0 1 .3-1l3.5-2.2h4.6a.9.9 0 0 1 .8.8.8.8 0 0 1-.8.7h-4l-3.3 2.1zm-39.1 0h-.4l-3.4-2.1h-4a.7.7 0 0 1-.7-.7.8.8 0 0 1 .7-.8h4.7l3.5 2.2a.8.8 0 0 1 .2 1 .6.6 0 0 1-.6.4zm33.7 1.7H22.5a.9.9 0 0 1-.8-.8.8.8 0 0 1 .8-.7h28.2a.7.7 0 0 1 .7.7.8.8 0 0 1-.7.8zM53.8 46H47a1.5 1.5 0 0 1-1.4-.7 1.6 1.6 0 0 1-.1-1.5l.9-2.1a1.5 1.5 0 0 1 1.5-1h6.9a1.7 1.7 0 0 1 1.3.7 1.6 1.6 0 0 1 .1 1.5l-.9 2.2a1.5 1.5 0 0 1-1.5.9zm-5.9-3.8h-.1l-.9 2.1c-.1 0-.1.1 0 .1h7l1-2.2h-7zM25.5 46h-6.9a1.6 1.6 0 0 1-1.5-.9l-.9-2.2a1.6 1.6 0 0 1 .2-1.5 1.7 1.7 0 0 1 1.3-.7h6.9a1.8 1.8 0 0 1 1.5 1l.9 2.1a1.6 1.6 0 0 1-.2 1.5 1.5 1.5 0 0 1-1.3.7zm-7.8-3.8h-.1l.9 2.2h7.1l-.9-2.1h-7zm8.9 12.7h-.3a.8.8 0 0 1-.4-1l2-4.1a3.5 3.5 0 0 1 3.1-2h10.9a3.5 3.5 0 0 1 3.1 1.8l2.2 4.2a.7.7 0 0 1-.3 1 .7.7 0 0 1-1-.3l-2.2-4.2a2.2 2.2 0 0 0-1.8-1H31a2.1 2.1 0 0 0-1.8 1.1l-1.9 4.1a.8.8 0 0 1-.7.4zm26.9 0h-4.7a.7.7 0 0 1-.7-.7.8.8 0 0 1 .7-.8h4.7a.8.8 0 0 1 .8.8.7.7 0 0 1-.8.7zm-29.1 0h-4.8a.7.7 0 0 1-.7-.7.8.8 0 0 1 .7-.8h4.8a.8.8 0 0 1 .7.8.7.7 0 0 1-.7.7z"/>
      <path className={fill} d="M38.2 23.4c10.8 0 12.3 1.4 15.3 6 .8 1.2 1.6 2.4 2.3 3.6a4.7 4.7 0 0 0 1 1l2.4 1.7a5.1 5.1 0 0 1 1.9 4v8.7a5.8 5.8 0 0 1-3.2 5.2.5.5 0 0 0-.3.5v2.6a1.5 1.5 0 0 1-1.5 1.5h-5.6a1.5 1.5 0 0 1-1.4-1.5v-1.9a.6.6 0 0 0-.6-.6H24.2a.6.6 0 0 0-.6.6v1.9a1.5 1.5 0 0 1-1.4 1.5h-5.6a1.4 1.4 0 0 1-1.4-1.5v-2.6a.7.7 0 0 0-.4-.5 5.8 5.8 0 0 1-3.2-5.2v-8.7a5.1 5.1 0 0 1 1.9-4l2.4-1.7a4.7 4.7 0 0 0 1-1c.7-1.2 1.5-2.4 2.3-3.6 3-4.6 4.5-6 15.3-6h3.7m0-2h-3.7c-11 0-13.4 1.4-17 6.9-.8 1.2-1.6 2.4-2.3 3.6s-.3.3-.5.5l-2.3 1.7a6.9 6.9 0 0 0-2.8 5.6v8.7a7.6 7.6 0 0 0 3.6 6.5v1.8a3.1 3.1 0 0 0 1 2.5 3.5 3.5 0 0 0 2.4 1h5.6a3.5 3.5 0 0 0 3.4-3.5v-.5h21.5v.5a3.5 3.5 0 0 0 3.4 3.5h5.6a3.4 3.4 0 0 0 3.5-3.5v-1.8a7.7 7.7 0 0 0 3.5-6.5v-8.7a7.1 7.1 0 0 0-2.7-5.6L58 32.4c-.2-.2-.4-.3-.5-.5s-1.5-2.4-2.3-3.6c-3.6-5.5-6-6.9-17-6.9z"/>
      <g clipPath="url(#clip-path)">
        <path className={fill} d="M16.8 34.5a1.1 1.1 0 0 1-.8-.3l-2.5-1.5a2.8 2.8 0 0 0-1.5-.4H9a1.5 1.5 0 0 1 0-3h3a6.8 6.8 0 0 1 3.1.8l2.4 1.6a1.5 1.5 0 0 1-.7 2.8z"/>
      </g>
      <g clipPath="url(#clip-path-2)">
        <path className={fill} d="M55.8 34.5a1.3 1.3 0 0 1-1.2-.8 1.4 1.4 0 0 1 .4-2l2.6-1.6a6 6 0 0 1 2.9-.8h3.1a1.5 1.5 0 0 1 0 3h-3.1a2.5 2.5 0 0 0-1.4.4l-2.5 1.5a1.1 1.1 0 0 1-.8.3z"/>
      </g>
    </svg>
  )
}, {
  title: i18n('Automatic trip logging'),
  name: 'driver-app-automatic-trip-logging',
  description: i18n('All journeys are automatically tracked. Relevant information about departure/arrival, addresses, duration, length, and type of trip are available, also in a historical view.'),
  tile: ({ className, fill, ...props }) => (
    <svg xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 72.8 72.8"
      className={className}
      {...props}
      width='100%'
      height='100%'>
      <path className={fill}
        d="M 20.1 42.4 h 13.6 a 2.7 2.7 0 0 0 2.4 1.6 a 2.7 2.7 0 0 0 2.5 -1.6 h 13.6 a 8.6 8.6 0 0 0 8.4 -6.5 a 2.8 2.8 0 0 0 2 -2.6 a 2.7 2.7 0 0 0 -2.1 -2.6 a 8.6 8.6 0 0 0 -8.3 -6.2 h -6.7 a 2.7 2.7 0 0 0 -5 0 H 24.3 a 5.9 5.9 0 0 0 -1.4 -3.1 a 9.5 9.5 0 0 0 1.7 -5.3 a 6.6 6.6 0 0 0 -13.2 0 a 10.3 10.3 0 0 0 1.7 5.3 a 6.4 6.4 0 1 0 11.2 5.1 h 16.2 a 2.7 2.7 0 0 0 5 0 h 6.7 a 6.7 6.7 0 0 1 6.3 4.5 a 2.6 2.6 0 0 0 -1.3 2.3 a 2.9 2.9 0 0 0 1.4 2.4 a 6.7 6.7 0 0 1 -6.4 4.7 H 38.7 a 2.8 2.8 0 0 0 -2.6 -1.8 a 2.7 2.7 0 0 0 -2.5 1.8 H 20.1 a 8.7 8.7 0 0 0 -8.4 6.7 a 2.1 2.1 0 0 0 -1.5 2 a 2.2 2.2 0 0 0 1.5 2.1 a 8.6 8.6 0 0 0 8.4 6.6 h 6.6 a 2.8 2.8 0 0 0 2.6 2 a 2.7 2.7 0 0 0 2.6 -2 H 48 a 6.4 6.4 0 0 0 6.3 5.4 a 6.4 6.4 0 1 0 0 -12.7 a 6.4 6.4 0 0 0 -6.3 5.3 H 31.7 a 2.8 2.8 0 0 0 -4.8 0 h -6.8 a 6.5 6.5 0 0 1 -6.4 -4.9 a 2.3 2.3 0 0 0 0.9 -1.8 a 2.4 2.4 0 0 0 -0.9 -1.8 a 6.7 6.7 0 0 1 6.4 -4.9 Z M 18 23 c -1.3 -1.3 -3.8 -4.1 -3.8 -6.9 a 3.9 3.9 0 0 1 7.7 0 c 0 2.8 -2.5 5.6 -3.9 6.9 Z m -2.3 -0.3 l 1.8 1.9 l 0.5 0.5 l 0.5 -0.5 a 16.8 16.8 0 0 0 1.9 -1.9 a 3.7 3.7 0 0 1 1.3 2.8 a 3.7 3.7 0 0 1 -3.7 3.6 a 3.6 3.6 0 0 1 -3.6 -3.6 a 3.7 3.7 0 0 1 1.3 -2.8 Z m 35 34.1 a 3.6 3.6 0 0 1 3.6 -3.6 a 3.7 3.7 0 1 1 0 7.3 a 3.6 3.6 0 0 1 -3.6 -3.7 Z" />
    </svg>
  )
}, {
  title: i18n('Trip categorization and quick notes'),
  name: 'driver-app-trip-categories',
  description: i18n('Select a default trip categorization or switch the purpose of a trip with a single tap. Add quick notes on a trip to easily remember which customer you visited or which event you attended.'),
  tile: ({ className, fill, ...props }) => (
    <svg xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 72.8 72.8"
      className={className}
      {...props}
      width='100%'
      height='100%'>
      <path className={fill} d="M44.4 44.3h3.8v1.5h-3.8zm-5.8 0h3.8v1.5h-3.8zm0-4.5h3.8v1.5h-3.8zm5.8 0h3.8v1.5h-3.8zm0 9h3.8v1.5h-3.8zm-5.8 0h3.8v1.5h-3.8zm0-13.4h3.8v1.5h-3.8zm5.8 0h3.8v1.5h-3.8zm-11.3 8.9h3.6v1.5h-3.6zm0 4.5h3.6v1.5h-3.6zm-7-14.3a2.8 2.8 0 1 0 2.7 2.8 2.8 2.8 0 0 0-2.7-2.8z"/>
      <path className={fill} d="M56.5 26.4l-9.7-9.6a1.8 1.8 0 0 0-1.4-.6H26.1a2 2 0 0 0-2 2V27a10.2 10.2 0 0 0-8.3 10.1c0 6.5 5.3 12.4 8.3 15.2v6.4a2 2 0 0 0 2 2H55a2 2 0 0 0 2-2V27.8a2.4 2.4 0 0 0-.5-1.4zM46.2 20l3.5 3.5 3.5 3.6h-7zM26 50.4c-2.6-2.4-7.5-7.8-7.5-13.3a7.6 7.6 0 1 1 15.1 0c0 5.4-5.3 11.1-7.6 13.3zM54.3 58H26.8v-6.3c2.1-1.9 5.8-5.9 7.5-10.4h2.4v-1.5h-1.9a12.4 12.4 0 0 0 .3-2.7v-.2h1.6v-1.5H35a9 9 0 0 0-8.2-7.3v-9.2h17.9v8.9a.8.8 0 0 0 .7.8h8.9z"/>
    </svg>
  )
}, {
  title: i18n('Driving logbook'),
  name: 'driver-app-logbook',
  description: i18n('It gets easy once you have all trips automatically tracked. You can export a driving logbook report at any time with a single click.'),
  tile: ({ className, fill, ...props }) => (
    <svg xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 72.8 72.8"
      className={className}
      {...props}
      width='100%'
      height='100%'>
      <path className={fill} d="M 35.2 20.9 a 2.4 2.4 0 1 0 -2.4 2.4 a 2.4 2.4 0 0 0 2.4 -2.4 Z" />
      <path className={fill} d="M 59.7 49.7 l -2.1 -1.4 v -5.2 l 2 -1.1 a 1.9 1.9 0 0 0 1 -1.7 a 2.1 2.1 0 0 0 -0.9 -1.7 L 40.8 25.7 a 13.3 13.3 0 0 0 1.1 -5 a 9.1 9.1 0 0 0 -18.2 0 a 13.4 13.4 0 0 0 1.1 5.1 l -7.5 4.3 a 8.6 8.6 0 0 0 -4.4 7.4 v 4.8 a 6.8 6.8 0 0 0 2.9 5.6 l 19.8 13.4 a 8 8 0 0 0 4.7 1.5 a 8.5 8.5 0 0 0 4.2 -1.1 l 15.1 -8.6 a 1.9 1.9 0 0 0 1 -1.7 a 2 2 0 0 0 -0.9 -1.7 Z M 32.8 14.4 a 6.3 6.3 0 0 1 6.3 6.3 c 0 4.6 -4.3 9.3 -6.3 11.3 c -2 -1.9 -6.4 -6.6 -6.4 -11.3 a 6.4 6.4 0 0 1 6.4 -6.3 Z m 2.9 16.7 l 3.2 2.2 l -7.5 4.3 l -4.6 -3.2 l 4 -2.3 l 1.5 1.4 l 0.5 0.5 l 0.5 -0.5 c 0.6 -0.5 1.4 -1.4 2.4 -2.4 Z m 7.5 28.2 a 6.1 6.1 0 0 1 -6.1 -0.3 L 17.4 45.6 a 3.9 3.9 0 0 1 -1.7 -3.3 v -4.8 a 5.8 5.8 0 0 1 2.9 -5 l 8.6 -4.9 a 33.4 33.4 0 0 0 2.5 3.4 L 25 33.7 a 0.6 0.6 0 0 0 -0.4 0.6 a 0.9 0.9 0 0 0 0.3 0.7 l 6 4.1 h 0.8 l 9 -5.1 a 0.7 0.7 0 0 0 0.4 -0.6 a 0.6 0.6 0 0 0 -0.4 -0.6 L 36.6 30 a 15.1 15.1 0 0 0 1.8 -2.6 l 18.8 12.8 L 39.8 50 a 5.4 5.4 0 0 0 -2.6 4.6 a 3.6 3.6 0 0 0 1.8 3.2 a 3.5 3.5 0 0 0 3.7 0 l 13.2 -7.4 l 1.3 0.9 Z m 11.7 -16.1 v 6.1 l -13 7.2 a 2 2 0 0 1 -2.1 0 a 2.1 2.1 0 0 1 -1.1 -1.9 a 3.9 3.9 0 0 1 1.9 -3.3 Z" />
      <path className={fill} d="M 19.3 35.7 a 0.7 0.7 0 1 0 -0.8 1.2 l 15.8 10.8 h 0.4 a 0.7 0.7 0 0 0 0.6 -0.3 a 0.8 0.8 0 0 0 -0.2 -1.1 Z" />
    </svg>
  )
}, {
  title: i18n('Communication'),
  name: 'driver-app-chat',
  description: i18n('Through the in-built chat you can be in contact with your service provider (e.g. mechanic). In this way, you will be always updated about important events that occur.'),
  tile: ({ className, fill, ...props }) => (
    <svg xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 72.8 72.8"
      className={className}
      {...props}
      width='100%'
      height='100%'>
      <path className={fill}
        d="M 36.3 21.5 h 16.5 a 0.5 0.5 0 0 0 0.5 -0.5 a 0.6 0.6 0 0 0 -0.5 -0.5 H 36.3 a 0.6 0.6 0 0 0 -0.5 0.5 a 0.5 0.5 0 0 0 0.5 0.5 Z M 60.1 26.2 H 36.3 a 0.6 0.6 0 0 0 -0.5 0.5 a 0.5 0.5 0 0 0 0.5 0.5 h 23.8 a 0.5 0.5 0 0 0 0.5 -0.5 a 0.5 0.5 0 0 0 -0.5 -0.5 Z M 10.8 37.3 h 15.8 a 0.5 0.5 0 0 0 0.5 -0.5 a 0.5 0.5 0 0 0 -0.5 -0.5 H 10.8 a 0.5 0.5 0 0 0 -0.5 0.5 a 0.5 0.5 0 0 0 0.5 0.5 Z M 34.4 42 H 10.8 a 0.5 0.5 0 0 0 -0.5 0.5 a 0.5 0.5 0 0 0 0.5 0.5 h 23.6 a 0.5 0.5 0 0 0 0.5 -0.5 a 0.5 0.5 0 0 0 -0.5 -0.5 Z M 34.4 46.8 H 10.8 a 0.5 0.5 0 1 0 0 1 h 23.6 a 0.5 0.5 0 0 0 0 -1 Z" />
      <path className={fill}
        d="M 64.9 12.1 H 32.1 a 7.5 7.5 0 0 0 -7.5 7.4 v 8.4 H 7.9 a 7.5 7.5 0 0 0 -7.5 7.4 v 12.5 a 7.6 7.6 0 0 0 6.9 7.5 v 2.6 a 2.8 2.8 0 0 0 2.9 2.8 a 2.7 2.7 0 0 0 1.9 -0.8 l 4.7 -4.6 h 23.9 a 7.6 7.6 0 0 0 7.5 -7.5 v -8.3 h 5.9 l 4.6 5.3 a 3.3 3.3 0 0 0 2.3 1 a 3 3 0 0 0 3 -3 v -3.3 h 0.9 a 7.6 7.6 0 0 0 7.5 -7.5 V 19.5 a 7.5 7.5 0 0 0 -7.5 -7.4 Z M 61.5 37 v 6.2 a 0.3 0.3 0 0 1 -0.3 0.3 a 0.4 0.4 0 0 1 -0.4 -0.1 L 55.3 37 h -8.6 v -1.3 a 5.9 5.9 0 0 0 -1.3 -3.7 h 14.7 a 0.5 0.5 0 0 0 0 -1 H 44.5 a 6.3 6.3 0 0 0 -4.2 -1.6 H 27.1 v -9.5 a 5.4 5.4 0 0 1 5.4 -5.3 h 32 a 5.4 5.4 0 0 1 5.4 5.3 v 11.7 a 5.4 5.4 0 0 1 -5.4 5.4 Z m -15.8 1 v 9.4 a 5.4 5.4 0 0 1 -5.4 5.4 H 15.7 L 10.5 58 a 0.4 0.4 0 0 1 -0.4 0.1 c -0.1 0 -0.3 -0.1 -0.3 -0.4 v -4.9 H 8.3 a 5.4 5.4 0 0 1 -5.4 -5.4 V 35.7 a 5.4 5.4 0 0 1 5.4 -5.3 h 32 a 5.4 5.4 0 0 1 5.4 5.3 Z" />
    </svg>
  )
}, {
  title: i18n('Choose between multiple vehicles'),
  name: 'driver-app-multi-vehicle-selection',
  description: i18n('Have the flexibility to choose between one or more vehicles that you are driving. It makes it easier to keep track of vehicle utilization and management.'),
  tile: ({ className, fill, ...props }) => (
    <svg xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 72.8 72.8"
      className={className}
      {...props}
      width='100%'
      height='100%'>
      <path className={fill} d="M24.1 41.1a1.3 1.3 0 0 0-1.3-.8h-4.9a1.3 1.3 0 0 0-1.1.6 1.2 1.2 0 0 0-.1 1.3l.6 1.5a1.3 1.3 0 0 0 1.3.8h4.9a1.1 1.1 0 0 0 1.1-.6 1.2 1.2 0 0 0 .1-1.3zM18.7 43l-.6-1.2h4.7l.5 1.2z"/>
      <path className={fill} d="M58.6 16H33.4a4.1 4.1 0 0 0-4 4.1v6c-7.5.1-9.3 1.3-11.9 5.2l-1.2 1.8-.9-.6h-3.5a.9.9 0 0 0-1 1 1 1 0 0 0 1 1h2.7l.3.2-1 .7a5.6 5.6 0 0 0-2.2 4.5V46a6.1 6.1 0 0 0 2.6 4.9v1a3 3 0 0 0 3 3.1h4a3.1 3.1 0 0 0 3-2.9h5.1v4a4 4 0 0 0 4 4.1h7v.8a2 2 0 0 0 1.2 1.8 1.2 1.2 0 0 0 .8.2 2 2 0 0 0 1.4-.6l2.2-2.1 1.8 3.7a1.6 1.6 0 0 0 1.1 1l.7.2.9-.2 2.9-1.5a2 2 0 0 0 .9-2.6l-.3-.7h4.6a4.1 4.1 0 0 0 4.1-4.1v-36a4.2 4.2 0 0 0-4.1-4.1zm0 41.5h-7.2l-.5-1.3h4.9a.8.8 0 0 0 .7-.5.8.8 0 0 0-.2-.8l-6.3-6h9.9v7.2a1.3 1.3 0 0 1-1.3 1.4zm-11.8-1.4h-.2l-.5.2-3 2.9V44.5l10.8 10.2h-4.2a.7.7 0 0 0-.6.3.9.9 0 0 0-.1.7l2.5 5.7-1.6.7-2.6-5.6a1 1 0 0 0-.5-.4zm-5.2 1.4h-8.2a1.3 1.3 0 0 1-1.3-1.4v-7.2h9.5zm-18.9-8.2h-3.4a.8.8 0 0 0-.7.8.7.7 0 0 0 .7.7h2.3v1.1a.3.3 0 0 1-.3.3h-4c-.1 0-.2 0-.2-.1a.3.3 0 0 1-.1-.2V50a1.1 1.1 0 0 0-.6-1 3.4 3.4 0 0 1-1.9-3v-6.2a2.8 2.8 0 0 1 1.1-2.3l1.6-1.2a4.7 4.7 0 0 0 1-1l1.6-2.5c2-3 2.9-3.9 10.3-3.9h.5v6.5h-9.3a.7.7 0 0 0-.7.7.8.8 0 0 0 .7.8h9.3v8.4h-3.2a2.8 2.8 0 0 0-2.4 1.5l-1.2 2.5zm37.2-29.2v7.2H32.1v-7.2a1.3 1.3 0 0 1 1.3-1.3h25.2a1.3 1.3 0 0 1 1.3 1.3zM48.4 47.4l-5.5-5.2a.6.6 0 0 0-.8-.1.6.6 0 0 0-.5.6v4.7h-9.5v-8.5h27.8v8.5zm-16.3-10v-8.6h27.8v8.6zm-5.7 10.1a1.2 1.2 0 0 1 1-.7h3.2v2.5h-5.1z"/>
    </svg>
  )
}];