import React from 'react';
import clsx from 'clsx';
import {
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary
} from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';
import Typography from '../../../components/Typography';
import styles from './styles.module.sass';

export default ({
  shaded,
  title,
  name,
  description,
  selected = false,
  onChange 
}) => (
  <ExpansionPanel
    elevation={0}
    expanded={selected} 
    onChange={onChange}
    classes={{
      root: clsx(
        styles.container,
        shaded ? styles.shaded : ''
      )
    }}>
    <ExpansionPanelSummary
      classes={{
        root: clsx(
          styles.titleContainer,
          shaded ? styles.shaded : ''
        ),
        content: clsx(
          styles.titleContent,
          shaded ? styles.shaded : ''
        ),
        expandIcon: clsx(
          styles.icon,
          shaded ? styles.shaded : ''
        )
      }}
      aria-controls={`narrow-${name}-content`}
      id={`narrow-${name}-header`}
      expandIcon={<ExpandMore/>}>
      <Typography variant='h4' 
        align='left'
        color='dark'>
        {title}
      </Typography>
    </ExpansionPanelSummary>
    <ExpansionPanelDetails 
      className={clsx(
        styles.description,
        shaded ? styles.shaded : ''
      )}>
      <Typography variant='subtitle2' 
        align='left'
        color='main'
        weight='light'>
        {description}
      </Typography>
    </ExpansionPanelDetails>
  </ExpansionPanel>
);