import React from "react";
import Layout from "../../components/Layout"
import SEO from "../../components/seo";
import sitemap from '../../data/sitemap';
import Hero from './Hero';
import VehicleCare from "./VehicleCare";
import Fleet from './Fleet';
import DriverApp from './DriverApp';
import Industries from './Industries';
import CallToAction from '../CallToAction';

export default ({ seo, location }) => (
  <Layout overlay page={sitemap.services}
    url='_url:services'>
    <SEO {...seo} location={location}/>
    <Hero/>
    <VehicleCare/>
    <Fleet/>
    <DriverApp/>
    <Industries/>
    <CallToAction/>
  </Layout>
)